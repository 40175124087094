<template>
  <div class="dashboard">
    <!-- ======================================================
         CASO 1: USUARIO SUPER ADMIN
         ====================================================== -->
    <div v-if="isSuperAdmin" class="mb-8">
      <!-- MENÚ PRINCIPAL
           Se muestra sólo si NO se ha seleccionado Gestión, Config, ni plan.
      -->
      <div
        class="mb-8"
        v-if="!showGestionDePlan && !showConfigPlan && !selectedPlanType"
      >
        <v-row class="mt-4" align="center" justify="center">
          <!-- Botón 1: Gestión de plan de acción -->
          <v-col cols="12" md="6" class="d-flex">
            <v-btn
              color="grey lighten-5"
              class="flex-grow-1 py-6"
              style="height: 150px"
              @click="
                showGestionDePlan = true;
                showConfigPlan = false;
              "
            >
              <v-icon left>mdi-format-list-checkbox</v-icon>
              Gestión de plan de acción
            </v-btn>
          </v-col>

          <!-- Botón 2: Configuración de tipo de plan -->
          <v-col cols="12" md="6" class="d-flex">
            <v-btn
              color="grey lighten-5"
              class="flex-grow-1 py-6"
              style="height: 150px"
              @click="listPlantype"
            >
              <v-icon left>mdi-cog-outline</v-icon>
              Configuración de tipo de plan
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <!-- Subsección: Gestión de plan de acción -->
      <div v-if="showGestionDePlan" class="mt-6">
        <!-- BOTÓN VOLVER AL MENÚ PRINCIPAL (SUPER ADMIN) -->
        <v-btn text color="primary" class="mb-4" @click="goBackToMenu">
          <v-icon left>mdi-arrow-left</v-icon>
          Volver menú principal
        </v-btn>

        <!-- SI NO HAY PLAN SELECCIONADO, MOSTRAR LISTA DE PLANES -->
        <div v-if="!selectedPlanType">
          <h2>Selecciona el tipo de plan</h2>
          <v-list>
            <v-list-item
              v-for="(plan, index) in allPlanTypes"
              :key="index"
              @click="selectPlanType(plan)"
            >
              <v-list-item-title>{{ plan.type_name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>

        <!-- SI HAY PLAN SELECCIONADO, MOSTRAR MÓDULOS -->
        <div v-else>
          <!-- BOTÓN “ELEGIR OTRO PLAN” -->
          <v-btn
            text
            color="primary"
            class="mb-4"
            @click="selectedPlanType = null"
          >
            <v-icon left>mdi-arrow-left</v-icon>
            Elegir otro plan
          </v-btn>

          <!-- Caso Clima Laboral -->
          <div v-if="selectedPlanType.type_name === 'CLIMA_LABORAL'">
            <div class="boxes">
              <v-row dense>
                <!-- Plan de acción -->
                <v-col cols="auto" sm="6" md="auto">
                  <router-link
                    :to="{
                      name: 'ListActionPlanEvidences',
                      params: { planTypeId: selectedPlanType?.id },
                    }"
                  >
                    <div class="text">
                      <v-icon>mdi-format-list-checkbox</v-icon>
                      <div class="text__description">Plan de acción</div>
                    </div>
                  </router-link>
                </v-col>

                <!-- Reporte -->
                <v-col cols="auto" sm="6" md="auto">
                  <router-link :to="{ name: 'ReportActionPlans' }">
                    <div class="text">
                      <v-icon>mdi-table-clock</v-icon>
                      <div class="text__description">Reporte</div>
                    </div>
                  </router-link>
                </v-col>

                <!-- Dashboard -->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="climaLaboral"
                    page="dashboardPlanAccion"
                    activity="verDashboard"
                  >
                    <router-link
                      :to="{ name: 'DashboardActionPlan' }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-chart-box</v-icon>
                        <div class="text__description">Dashboard</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Notificaciones -->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="climaLaboral"
                    page="listaSolicitudesActualizacion"
                    activity="listarSolicitudesActualizacion"
                  >
                    <router-link
                      :to="{ name: 'NotificationActionPlan' }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-flag-outline</v-icon>
                        <div class="text__description">Notificaciones</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Configuracion de aprobadores-->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="climaLaboral"
                    page="configuracionUsuariosAprobadores"
                    activity="configurarUsuariosAprobadores"
                  >
                    <router-link
                      :to="{
                        name: 'actionPlanApproverSettings',
                        params: { planTypeId: selectedPlanType?.id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-cog</v-icon>
                        <div class="text__description">
                          Configuración de aprobadores
                        </div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Informe-->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="climaLaboral"
                    page="listaInformesPlanAccion"
                    activity="listarInformesPlanAccion"
                  >
                    <router-link
                      :to="{ name: 'ExportReportActionPlan', params: { planTypeId: selectedPlanType?.id },}"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-file-document-outline</v-icon>
                        <div class="text__description">Informes</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- Caso Satisfacción al Cliente -->
          <div
            v-else-if="selectedPlanType.type_name === 'SATISFACCION_CLIENTE'"
          >
            <div class="boxes">
              <v-row dense>
                <!-- Plan de acción -->
                <v-col cols="auto" sm="6" md="auto">
                  <router-link
                    :to="{
                      name: 'ListActionPlanEvidencesSatifaction',
                      params: { planTypeId: selectedPlanType?.id },
                    }"
                  >
                    <div class="text">
                      <v-icon>mdi-format-list-checkbox</v-icon>
                      <div class="text__description">Plan de acción</div>
                    </div>
                  </router-link>
                </v-col>

                <!-- Reporte -->
                <v-col cols="auto" sm="6" md="auto">
                  <router-link :to="{ name: 'ReportActionPlansSatisfaction' }">
                    <div class="text">
                      <v-icon>mdi-table-clock</v-icon>
                      <div class="text__description">Reporte</div>
                    </div>
                  </router-link>
                </v-col>

                <!-- Dashboard -->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="satisfaccionCliente"
                    page="dashboardPlanAccion"
                    activity="verDashboard"
                  >
                    <router-link
                      :to="{ name: 'DashboardActionPlanSatisfaction' }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-chart-box</v-icon>
                        <div class="text__description">Dashboard</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Notificaciones -->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="satisfaccionCliente"
                    page="listaSolicitudesActualizacion"
                    activity="listarSolicitudesActualizacion"
                  >
                    <router-link
                      :to="{ name: 'NotificationActionPlanSatisfaction' }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-flag-outline</v-icon>
                        <div class="text__description">Notificaciones</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Configuracion de aprobadores-->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="satisfaccionCliente"
                    page="configuracionUsuariosAprobadores"
                    activity="configurarUsuariosAprobadores"
                  >
                    <router-link
                      :to="{
                        name: 'actionPlanSatisfactionApproverSettings',
                        params: { planTypeId: selectedPlanType?.id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-cog</v-icon>
                        <div class="text__description">
                          Configuración de aprobadores
                        </div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>
                
                <!-- Informe-->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="satisfaccionCliente"
                    page="listaInformesPlanAccion"
                    activity="listarInformesPlanAccion"
                  >
                    <router-link
                      :to="{ name: 'ExportReportActionPlanSatisfaction', params: { planTypeId: selectedPlanType?.id },}"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-file-document-outline</v-icon>
                        <div class="text__description">Informes</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- Caso Genérico (otros planes) -->
          <div v-else>
            <h2 class="mb-2">
              Plan de acción {{ selectedPlanType?.type_name }}
            </h2>
            <div class="boxes">
              <v-row dense>
                <!-- Plan de acción -->
                <v-col cols="auto" sm="6" md="auto">
                  <router-link
                    :to="{
                      name: 'ListEvidenceActionPlanDinamic',
                      params: { planTypeId: selectedPlanType?.id },
                    }"
                  >
                    <div class="text">
                      <v-icon>mdi-format-list-checkbox</v-icon>
                      <div class="text__description">Plan de acción</div>
                    </div>
                  </router-link>
                </v-col>

                <!-- Reporte -->
                <v-col cols="auto" sm="6" md="auto">
                  <router-link
                    :to="{
                      name: 'ReportActionPlansDinamic',
                      params: { planTypeId: selectedPlanType?.id },
                    }"
                  >
                    <div class="text">
                      <v-icon>mdi-table-clock</v-icon>
                      <div class="text__description">Reporte</div>
                    </div>
                  </router-link>
                </v-col>

                <!-- Dashboard -->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="satisfaccionCliente"
                    page="dashboardPlanAccion"
                    activity="verDashboard"
                  >
                    <router-link
                      :to="{
                        name: 'DashboardActionPlanDinamic',
                        params: { planTypeId: selectedPlanType?.id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-chart-box</v-icon>
                        <div class="text__description">Dashboard</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Notificaciones -->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="satisfaccionCliente"
                    page="listaSolicitudesActualizacion"
                    activity="listarSolicitudesActualizacion"
                  >
                    <router-link
                      :to="{
                        name: 'NotificationActionPlanDinamic',
                        params: { planTypeId: selectedPlanType?.id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-flag-outline</v-icon>
                        <div class="text__description">Notificaciones</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Configuracion de aprobadores-->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="tipoPlanDinamico"
                    page="configuracionUsuariosAprobadores"
                    activity="configurarUsuariosAprobadores"
                  >
                    <router-link
                      :to="{
                        name: 'ApproverSettingsDinamic',
                        params: { planTypeId: selectedPlanType?.id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-cog</v-icon>
                        <div class="text__description">
                          Configuración de aprobadores
                        </div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Informe-->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="tipoPlanDinamico"
                    page="listaInformesPlanAccion"
                    activity="listarInformesPlanAccion"
                  >
                    <router-link
                      :to="{ name: 'ExportReportDinamicPage', params: { planTypeId: selectedPlanType?.id },}"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-file-document-outline</v-icon>
                        <div class="text__description">Informes</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>

      <!-- Aquí podrías agregar la subsección: Configuración de tipo de plan 
           (showConfigPlan) si lo deseas. 
      -->
    </div>

    <!-- ======================================================
         CASO 2: USUARIO NO SUPER ADMIN
         ====================================================== -->
    <div v-else>
      <!-- Sub-caso A: 0 PlanTypes -->
      <div v-if="filteredPlanTypes.length === 0">
        <h3>No tienes planes de acción disponibles.</h3>
      </div>
      
      <!-- Sub-caso B: 1 PlanType (módulos directos, como ya tenías) -->
      <div v-else-if="filteredPlanTypes.length === 1">

        <div class="dashboard__body">
          <h2 class="mb-2" >
            {{ filteredPlanTypes[0]?.type_name }}
          </h2>
          <div class="boxes" >
            <v-row dense>
              <!-- Plan de acción -->
              <v-col cols="auto" sm="6" md="auto">
                <router-link
                  :to="{
                    name: dinamicRedirection(filteredPlanTypes[0]).redirectName.actionPlanList,
                    params: { planTypeId: filteredPlanTypes[0].id },
                  }"
                >
                  <div class="text">
                    <v-icon>mdi-format-list-checkbox</v-icon>
                    <div class="text__description">Plan de acción</div>
                  </div>
                </router-link>
              </v-col>

              <!-- Reporte -->
              <v-col cols="auto" sm="6" md="auto">
                <router-link :to="{ name: dinamicRedirection(filteredPlanTypes[0]).redirectName.report }">
                  <div class="text">
                    <v-icon>mdi-table-clock</v-icon>
                    <div class="text__description">Reporte</div>
                  </div>
                </router-link>
              </v-col>

              <!-- Dashboard -->
              <v-col cols="auto" sm="6" md="auto">
                <validate-activity
                  v-slot:default="{ isDisabled }"
                  :module="dinamicRedirection(filteredPlanTypes[0]).module"
                  page="dashboardPlanAccion"
                  activity="verDashboard"
                >
                  <router-link
                    :to="{ name: dinamicRedirection(filteredPlanTypes[0]).redirectName.dashboard }"
                    v-if="!isDisabled"
                  >
                    <div class="text">
                      <v-icon>mdi-chart-box</v-icon>
                      <div class="text__description">Dashboard</div>
                    </div>
                  </router-link>
                </validate-activity>
              </v-col>

              <!-- Notificaciones -->
              <v-col cols="auto" sm="6" md="auto">
                <validate-activity
                  v-slot:default="{ isDisabled }"
                  :module="dinamicRedirection(filteredPlanTypes[0]).module"
                  page="listaSolicitudesActualizacion"
                  activity="listarSolicitudesActualizacion"
                >
                  <router-link
                    :to="{ name: dinamicRedirection(filteredPlanTypes[0]).redirectName.notifications }"
                    v-if="!isDisabled"
                  >
                    <div class="text">
                      <v-icon>mdi-flag-outline</v-icon>
                      <div class="text__description">Notificaciones</div>
                    </div>
                  </router-link>
                </validate-activity>
              </v-col>

              <!-- Configuracion de aprobadores-->
              <v-col cols="auto" sm="6" md="auto">
                <validate-activity
                  v-slot:default="{ isDisabled }"
                  :module="dinamicRedirection(filteredPlanTypes[0]).module"
                  page="configuracionUsuariosAprobadores"
                  activity="configurarUsuariosAprobadores"
                >
                  <router-link
                    :to="{
                      name: dinamicRedirection(filteredPlanTypes[0]).redirectName.approversSettings,
                      params: { planTypeId: filteredPlanTypes[0].id },
                    }"
                    v-if="!isDisabled"
                  >
                    <div class="text">
                      <v-icon>mdi-cog</v-icon>
                      <div class="text__description">
                        Configuración de aprobadores
                      </div>
                    </div>
                  </router-link>
                </validate-activity>
              </v-col>

              <!-- Informe-->
              <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    :module="dinamicRedirection(filteredPlanTypes[0]).module"
                    page="listaInformesPlanAccion"
                    activity="listarInformesPlanAccion"
                  >
                    <router-link
                      :to="{ name: dinamicRedirection(filteredPlanTypes[0]).redirectName.exportReport, 
                      params: { planTypeId: filteredPlanTypes[0].id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-file-document-outline</v-icon>
                        <div class="text__description">Informes</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>
            </v-row>
          </div>

          <!-- <v-divider v-if="validateTitle().actionPlanCL.length"></v-divider>

          <h2 class="mt-2 mb-2" v-if="validateTitle().actionPlanSC.length">
            Satisfacción al cliente
          </h2>
          <div class="boxes" v-if="validateTitle().actionPlanSC.length">
            <v-row dense>
              <v-col cols="auto" sm="6" md="auto">
                <router-link
                  :to="{
                    name: 'ListActionPlanEvidencesSatifaction',
                    params: { planTypeId: filteredPlanTypes[0].id },
                  }"
                >
                  <div class="text">
                    <v-icon>mdi-format-list-checkbox</v-icon>
                    <div class="text__description">Plan de acción</div>
                  </div>
                </router-link>
              </v-col>

              <v-col cols="auto" sm="6" md="auto">
                <router-link :to="{ name: 'ReportActionPlansSatisfaction' }">
                  <div class="text">
                    <v-icon>mdi-table-clock</v-icon>
                    <div class="text__description">Reporte</div>
                  </div>
                </router-link>
              </v-col>

              <v-col cols="auto" sm="6" md="auto">
                <validate-activity
                  v-slot:default="{ isDisabled }"
                  module="satisfaccionCliente"
                  page="dashboardPlanAccion"
                  activity="verDashboard"
                >
                  <router-link
                    :to="{ name: 'DashboardActionPlanSatisfaction' }"
                    v-if="!isDisabled"
                  >
                    <div class="text">
                      <v-icon>mdi-chart-box</v-icon>
                      <div class="text__description">Dashboard</div>
                    </div>
                  </router-link>
                </validate-activity>
              </v-col>

              <v-col cols="auto" sm="6" md="auto">
                <validate-activity
                  v-slot:default="{ isDisabled }"
                  module="satisfaccionCliente"
                  page="listaSolicitudesActualizacion"
                  activity="listarSolicitudesActualizacion"
                >
                  <router-link
                    :to="{ name: 'NotificationActionPlanSatisfaction' }"
                    v-if="!isDisabled"
                  >
                    <div class="text">
                      <v-icon>mdi-flag-outline</v-icon>
                      <div class="text__description">Notificaciones</div>
                    </div>
                  </router-link>
                </validate-activity>
              </v-col>

              <v-col cols="auto" sm="6" md="auto">
                <validate-activity
                  v-slot:default="{ isDisabled }"
                  module="satisfaccionCliente"
                  page="configuracionUsuariosAprobadores"
                  activity="configurarUsuariosAprobadores"
                >
                  <router-link
                    :to="{
                      name: 'actionPlanSatisfactionApproverSettings',
                      params: { planTypeId: filteredPlanTypes[0].id },
                    }"
                    v-if="!isDisabled"
                  >
                    <div class="text">
                      <v-icon>mdi-cog</v-icon>
                      <div class="text__description">
                        Configuración de aprobadores
                      </div>
                    </div>
                  </router-link>
                </validate-activity>
              </v-col>
            </v-row>
          </div> -->
        </div>
      </div>

      <!-- Sub-caso C: Más de 1 PlanType -->
      <div v-else>
        <!-- SI NO HAY PLAN SELECCIONADO, MOSTRAR LISTA -->
        <div v-if="!selectedPlanType">
          <h2>Selecciona uno de los planes de acción disponibles:</h2>
          <v-list>
            <v-list-item
              v-for="(plan, index) in filteredPlanTypes"
              :key="index"
              @click="selectedPlanType = plan"
            >
              <v-list-item-title>{{ plan.type_name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>

        <!-- SI YA HAY UN PLAN SELECCIONADO, MOSTRAR MÓDULOS -->
        <div v-else>
          <!-- BOTÓN VOLVER PARA RE-ELEGIR OTRO PLAN -->
          <v-btn
            text
            color="primary"
            class="mb-4"
            @click="selectedPlanType = null"
          >
            <v-icon left>mdi-arrow-left</v-icon>
            Elegir otro plan
          </v-btn>

          <!-- Caso Clima Laboral -->
          <div v-if="selectedPlanType.type_name === 'CLIMA_LABORAL'">
            <div class="boxes">
              <v-row dense>
                <!-- Plan de acción -->
                <v-col cols="auto" sm="6" md="auto">
                  <router-link
                    :to="{
                      name: 'ListActionPlanEvidences',
                      params: { planTypeId: selectedPlanType?.id },
                    }"
                  >
                    <div class="text">
                      <v-icon>mdi-format-list-checkbox</v-icon>
                      <div class="text__description">Plan de acción</div>
                    </div>
                  </router-link>
                </v-col>

                <!-- Reporte -->
                <v-col cols="auto" sm="6" md="auto">
                  <router-link :to="{ name: 'ReportActionPlans' }">
                    <div class="text">
                      <v-icon>mdi-table-clock</v-icon>
                      <div class="text__description">Reporte</div>
                    </div>
                  </router-link>
                </v-col>

                <!-- Dashboard -->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="climaLaboral"
                    page="dashboardPlanAccion"
                    activity="verDashboard"
                  >
                    <router-link
                      :to="{ name: 'DashboardActionPlan' }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-chart-box</v-icon>
                        <div class="text__description">Dashboard</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Notificaciones -->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="climaLaboral"
                    page="listaSolicitudesActualizacion"
                    activity="listarSolicitudesActualizacion"
                  >
                    <router-link
                      :to="{ name: 'NotificationActionPlan' }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-flag-outline</v-icon>
                        <div class="text__description">Notificaciones</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Configuracion de aprobadores-->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="climaLaboral"
                    page="configuracionUsuariosAprobadores"
                    activity="configurarUsuariosAprobadores"
                  >
                    <router-link
                      :to="{
                        name: 'actionPlanApproverSettings',
                        params: { planTypeId: selectedPlanType?.id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-cog</v-icon>
                        <div class="text__description">
                          Configuración de aprobadores
                        </div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Informe-->
              <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="climaLaboral"
                    page="listaInformesPlanAccion"
                    activity="listarInformesPlanAccion"
                  >
                    <router-link
                      :to="{ name: 'ExportReportActionPlan', 
                      params: { planTypeId: filteredPlanTypes[0].id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-file-document-outline</v-icon>
                        <div class="text__description">Informes</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- Caso Satisfacción al Cliente -->
          <div
            v-else-if="selectedPlanType.type_name === 'SATISFACCION_CLIENTE'"
          >
            <div class="boxes">
              <v-row dense>
                <!-- Plan de acción -->
                <v-col cols="auto" sm="6" md="auto">
                  <router-link
                    :to="{
                      name: 'ListActionPlanEvidencesSatifaction',
                      params: { planTypeId: selectedPlanType?.id },
                    }"
                  >
                    <div class="text">
                      <v-icon>mdi-format-list-checkbox</v-icon>
                      <div class="text__description">Plan de acción</div>
                    </div>
                  </router-link>
                </v-col>

                <!-- Reporte -->
                <v-col cols="auto" sm="6" md="auto">
                  <router-link :to="{ name: 'ReportActionPlansSatisfaction' }">
                    <div class="text">
                      <v-icon>mdi-table-clock</v-icon>
                      <div class="text__description">Reporte</div>
                    </div>
                  </router-link>
                </v-col>

                <!-- Dashboard -->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="satisfaccionCliente"
                    page="dashboardPlanAccion"
                    activity="verDashboard"
                  >
                    <router-link
                      :to="{ name: 'DashboardActionPlanSatisfaction' }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-chart-box</v-icon>
                        <div class="text__description">Dashboard</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Notificaciones -->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="satisfaccionCliente"
                    page="listaSolicitudesActualizacion"
                    activity="listarSolicitudesActualizacion"
                  >
                    <router-link
                      :to="{ name: 'NotificationActionPlanSatisfaction' }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-flag-outline</v-icon>
                        <div class="text__description">Notificaciones</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Configuracion de aprobadores-->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="satisfaccionCliente"
                    page="configuracionUsuariosAprobadores"
                    activity="configurarUsuariosAprobadores"
                  >
                    <router-link
                      :to="{
                        name: 'actionPlanSatisfactionApproverSettings',
                        params: { planTypeId: selectedPlanType?.id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-cog</v-icon>
                        <div class="text__description">
                          Configuración de aprobadores
                        </div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Informe-->
              <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="satisfaccionCliente"
                    page="listaInformesPlanAccion"
                    activity="listarInformesPlanAccion"
                  >
                    <router-link
                      :to="{ name: 'ExportReportActionPlanSatisfaction', 
                      params: { planTypeId: filteredPlanTypes[0].id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-file-document-outline</v-icon>
                        <div class="text__description">Informes</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- Caso Genérico (otros planes) -->
          <div v-else>
            <h2 class="mb-2">Plan de acción genérico</h2>
            <div class="boxes">
              <v-row dense>
                <!-- Plan de acción -->
                <v-col cols="auto" sm="6" md="auto">
                  <!-- Plan de acción -->
                  <router-link
                    :to="{
                      name: 'ListEvidenceActionPlanDinamic',
                      params: { planTypeId: selectedPlanType?.id },
                    }"
                  >
                    <div class="text">
                      <v-icon>mdi-format-list-checkbox</v-icon>
                      <div class="text__description">Plan de acción</div>
                    </div>
                  </router-link>
                </v-col>

                <!-- Reporte -->
                <v-col cols="auto" sm="6" md="auto">
                  <!-- Reporte -->
                  <router-link
                    :to="{
                      name: 'ReportActionPlansDinamic',
                      params: { planTypeId: selectedPlanType?.id },
                    }"
                  >
                    <div class="text">
                      <v-icon>mdi-table-clock</v-icon>
                      <div class="text__description">Reporte</div>
                    </div>
                  </router-link>
                </v-col>

                <!-- Dashboard -->
                <v-col cols="auto" sm="6" md="auto">
                  <!-- Dashboard -->
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="tipoPlanDinamico"
                    page="dashboardPlanAccion"
                    activity="verDashboard"
                  >
                    <router-link
                      :to="{
                        name: 'DashboardActionPlanDinamic',
                        params: { planTypeId: selectedPlanType?.id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-chart-box</v-icon>
                        <div class="text__description">Dashboard</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Notificaciones -->
                <v-col cols="auto" sm="6" md="auto">
                  <!-- Notificaciones -->
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="tipoPlanDinamico"
                    page="listaSolicitudesActualizacion"
                    activity="listarSolicitudesActualizacion"
                  >
                    <router-link
                      :to="{
                        name: 'NotificationActionPlanDinamic',
                        params: { planTypeId: selectedPlanType?.id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-flag-outline</v-icon>
                        <div class="text__description">Notificaciones</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Configuracion de aprobadores-->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="tipoPlanDinamico"
                    page="configuracionUsuariosAprobadores"
                    activity="configurarUsuariosAprobadores"
                  >
                    <router-link
                      :to="{
                        name: 'ApproverSettingsDinamic',
                        params: { planTypeId: selectedPlanType?.id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-cog</v-icon>
                        <div class="text__description">
                          Configuración de aprobadores
                        </div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>

                <!-- Informe-->
                <v-col cols="auto" sm="6" md="auto">
                  <validate-activity
                    v-slot:default="{ isDisabled }"
                    module="tipoPlanDinamico"
                    page="listaInformesPlanAccion"
                    activity="listarInformesPlanAccion"
                  >
                    <router-link
                      :to="{ name: 'ExportReportDinamicPage', 
                      params: { planTypeId: filteredPlanTypes[0].id },
                      }"
                      v-if="!isDisabled"
                    >
                      <div class="text">
                        <v-icon>mdi-file-document-outline</v-icon>
                        <div class="text__description">Informes</div>
                      </div>
                    </router-link>
                  </validate-activity>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import ValidateActivity from "@/components/global/ValidateActivity.vue";

export default {
  name: "Dashboard",
  components: {
    ValidateActivity,
  },
  data() {
    return {
      isLoadingPlanType: false,
      // Para SUPER ADMIN
      showGestionDePlan: false,
      showConfigPlan: false,
      // Plan seleccionado
      selectedPlanType: null,
    };
  },
  computed: {
    ...mapState("plan_type", ["allPlanTypes"]),

    profiles() {
      const companies = this.$store.state.auth.user?.companies || [];
      const profiles = [];
      if (Array.isArray(companies)) {
        companies.forEach((company) => {
          company.profiles.forEach((profile) => {
            profiles.push(profile.role);
          });
        });
      }
      return profiles;
    },

    isSuperAdmin() {
      return this.profiles.some((profile) => profile.name === "Super Admin");
    },

    filteredPlanTypes() {
      if (this.isSuperAdmin) {
        return this.allPlanTypes; // Muestra todos si es superadmin
      }
      const userProfileIds = this.profiles.map((p) => p.id);
      return this.allPlanTypes.filter((pt) =>
        pt.profiles?.some((pf) => userProfileIds.includes(pf.profile_id))
      );
    },

    planTypes() {
      if (this.allPlanTypes && this.allPlanTypes.length > 0) {
        return this.allPlanTypes.map((plan) => ({
          ...plan,
          type_name: ["SATISFACCION_CLIENTE", "CLIMA_LABORAL"].includes(
            plan.type_name
          )
            ? plan.type_name.replace(/_/g, " ") // Reemplaza "_" solo si es necesario
            : plan.type_name,
        }));
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions("plan_type", ["listAllPlanTypes", "cleanPlanTypeId"]),

    async getPlanType() {
      this.isLoadingPlanType = true;
      try {
        const { error } = await this.listAllPlanTypes();
        if (error) showError(error);
      } catch (err) {
        showError(err);
      }
      this.isLoadingPlanType = false;
    },

    goBackToMenu() {
      // Vuelve al menú principal de superadmin
      this.showGestionDePlan = false;
      this.showConfigPlan = false;
      this.selectedPlanType = null;
    },

    // Ir a la ruta de configuración (la que ya tenías)
    listPlantype() {
      this.$router.push({ name: "ListPlanType" });
    },

    // Cuando super admin da click a un plan
    selectPlanType(plan) {
      this.selectedPlanType = plan;
    },

    // Para NO super admin (o si lo usas en otro lado),
    // ir a la ruta según plan.type_name
    goToPlanType(plan) {
      switch (plan.type_name) {
        case "CLIMA_LABORAL":
          this.$router.push({ name: "ListActionPlanEvidences" });
          break;
        case "SATISFACCION_CLIENTE":
          this.$router.push({ name: "ListActionPlanEvidencesSatifaction" });
          break;
        default:
          this.$router.push({
            name: "ListEvidenceActionPlanDinamic",
            params: { planTypeId: plan.id },
          });
          break;
      }
    },

    dinamicRedirection(plan){
      switch (plan?.type_name) {
        case "CLIMA_LABORAL":
          return { 
            module: 'climaLaboral', 
            redirectName: {
              actionPlanList:'ListActionPlanEvidences',
              report:'ReportActionPlans',
              dashboard:'DashboardActionPlan',
              notifications:'NotificationActionPlan',
              approversSettings:'actionPlanApproverSettings',
              exportReport:'ExportReportActionPlan'
            }}
        case "SATISFACCION_CLIENTE":
        return { 
          module: 'satisfaccionCliente', 
          redirectName: {
              actionPlanList:'ListActionPlanEvidencesSatifaction',
              report:'ReportActionPlansSatisfaction',
              dashboard:'DashboardActionPlanSatisfaction',
              notifications:'NotificationActionPlanSatisfaction',
              approversSettings:'actionPlanSatisfactionApproverSettings',
              exportReport:'ExportReportActionPlanSatisfaction'
            }}
        default:
        return { 
          module: 'tipoPlanDinamico', 
          redirectName: {
              actionPlanList:'ListEvidenceActionPlanDinamic',
              report:'ReportActionPlansDinamic',
              dashboard:'DashboardActionPlanDinamic',
              notifications:'NotificationActionPlanDinamic',
              approversSettings:'ApproverSettingsDinamic',
              exportReport:'ExportReportDinamicPage'
            }}
      }
    },

    validateTitle() {
      const activities = this.$store.state.auth.activities || [];
      const actionPlanClimaLaboral = activities.filter(
        (activity) => activity.module_name === "climaLaboral"
      );
      const actionPlanSatisfactionClient = activities.filter(
        (activity) => activity.module_name === "satisfaccionCliente"
      );
      return {
        actionPlanCL: actionPlanClimaLaboral,
        actionPlanSC: actionPlanSatisfactionClient,
      };
    },
  },
  created() {
    this.getPlanType();
  },
  beforeDestroy() {
    this.cleanPlanTypeId();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/dashboardHome.scss";
</style>
